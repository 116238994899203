// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgSynopticLogoCompact = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={29}
    viewBox="0 0 21 29"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.267.025C6.462.025.02 6.836.02 16.538.017 28.39 10.267 28.024 10.267 28.024s10.249.366 10.249-11.485c0-9.701-6.444-16.512-10.249-16.512"
    />
  </svg>
);
export const SynopticLogoCompactIcon = forwardRef(SvgSynopticLogoCompact);
